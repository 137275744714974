import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {CookiesProvider} from "react-cookie";
import {LanguageProvider} from "./main/components/translator/languageContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <LanguageProvider>
            <App/>
        </LanguageProvider>
    </CookiesProvider>
);


