import React from 'react';
import {Grid} from '@mui/material';
import Navbar from '../../components/navbar/navbar';
import ResultDatatableMain from "../../components/datatable/resultDatatableMain";


function Results() {
    return (
        <div style={{background: "#303841", minHeight: "100vh"}}>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
                color="white"
            >
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={11} md={7} sx={{paddingTop: 3}}>
                    <ResultDatatableMain/>
                </Grid>
            </Grid>
        </div>
    );

}

export default Results;
