import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import img2 from "./images/2.jpg";
import img3 from "./images/3.jpg";
import img4 from "./images/4.jpg";
import img5 from "./images/5.jpg";
import img6 from "./images/6.jpg";
import img7 from "./images/7.jpg";
import img8 from "./images/8.jpg";
import "./animationImageGallery.scss";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function ImageGallery() {
    return (
        <ImageList
            xs={{}}
            variant="quilted"
            cols={4}
            rowHeight={121}
            style={{overflow: "hidden"}}
        >
            {itemData.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                        className="fadeSlideIn"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

const itemData = [
    {
        img: img8,
        rows: 2,
        cols: 4,
    },
    {
        img: img5,
        rows: 2,
        cols: 2,
    },
    {
        img: img4,
        rows: 1,
        cols: 1,
    },
    {
        img: img2,
        rows: 1,
        cols: 1,
    },
    {
        img: img3,
        rows: 1,
        cols: 2,
    },

    {
        img: img6,
        rows: 2,
        cols: 2,
    },
    {
        img: img7,
        rows: 2,
        cols: 2,
    },
];
