import "./styles/datatable.scss";
import {DataGrid} from "@mui/x-data-grid";
import {userColumnCategories} from "../../data/datatable-source";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "react-bootstrap";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import AlertTitle from "@mui/material/AlertTitle";
import {translateGender} from "../../helper/gender-helper";
import DeleteModal from "../modal/deleteModal";
import {csCZ} from "@mui/x-data-grid/locales";

const CategoryDatatableAdmin = () => {
    const [data, setData] = useState([]);
    const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);
    const [checkBoxParams, setCheckBoxParams] = useState([]);
    const [showPositiveAlert, setShowPositiveAlert] = useState(false);
    const [showNegativeAlert, setShowNegativeAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalOpened, setModalOpened] = useState(false);
    const [passedText, setPassedText] = useState("");
    const [deleteMany, setDeleteMany] = useState(false);
    const [raceToDeleteId, setRaceToDeleteId] = useState("");
    const handleCheckboxSelection = (params) => {
        params.length > 0 ? setMultipleRowsSelected(true) : setMultipleRowsSelected(false);
        setCheckBoxParams(params);
    }

    const handleDeleteMany = async () => {
        let raceListRequest = checkBoxParams.map(id => {
            return id;
        })

        let raceListResponse = await axios.post("/api/race/list", {
            filterList: [
                {key: "categoryContextList.id", valueList: raceListRequest}
            ]
        })

        if (raceListResponse.data.itemList.length !== 0) {
            setErrorMessage("Kategorie nelze smazat, protože jsou navázány na závody: " + raceListResponse.data.itemList.map(item => item.name).join(', '));
            setShowNegativeAlert(true);
            return
        }

        for (let id of checkBoxParams) {
            await axios.get("/api/category/delete", {
                params: {
                    id: id
                }
            })
        }
        setShowPositiveAlert(true);
        setTimeout(() => {
            setShowPositiveAlert(false);
        }, 5000); // Hide alert after 5 seconds
        await fetchData();
    }

    const handleDelete = async (id) => {
        let raceListResponse = await axios.post("/api/race/list", {
            filterList: [
                {key: "categoryContextList.id", valueList: [id]}
            ]
        })

        if (raceListResponse.data.itemList.length !== 0) {
            setErrorMessage("Kategorie nelze smazat, protože je navázána na závody: " + raceListResponse.data.itemList.map(item => item.name).join(', '));
            setShowNegativeAlert(true);
            return
        }

        await axios.get("/api/category/delete", {
            params: {
                id: id
            }
        })
        setShowPositiveAlert(true);
        setTimeout(() => {
            setShowPositiveAlert(false);
        }, 5000); // Hide alert after 5 seconds
        setData(data.filter((item) => item.id !== id));
    };

    const handleOpenModal = (text, id, many, event) => {
        event.stopPropagation(); // Stop event propagation
        setPassedText(text);
        setRaceToDeleteId(id);
        setModalOpened(true);
        setDeleteMany(many);
    }

    const getAgeString = (item) => {
        return item.ageFrom + "-" + item.ageTo
    }

    const fetchData = async () => {
        try {
            let raceListResponse = await axios.post("/api/category/list", {});
            let data = raceListResponse.data.itemList.map((item) => {
                return {
                    id: item._id,
                    name: item.name,
                    gender: translateGender(item.gender),
                    age: getAgeString(item)
                };
            })
            setData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const actionColumn = [
        {
            field: "action",
            headerName: "Akce",
            width: 300,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={`/admin/categories/update/${params.row.id}`} style={{textDecoration: "none"}}>
                            <div className="editButton">Upravit</div>
                        </Link>
                        <Link to={`/admin/categories/linkedRaces/${params.row.id}`} style={{textDecoration: "none"}}>
                            <div className="raceViewButton">Navázané závody</div>
                        </Link>
                        <div
                            className="deleteButton"
                            onClick={(event) => handleOpenModal("Opravdu chcete smazat kategorii: " + params.row.name + " ?", params.row.id, false, event)}
                        >
                            Smazat
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            {showPositiveAlert && (
                <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success" className="alert">
                    Kategorie úspěšně smazána
                </Alert>

            )}
            {showNegativeAlert && (
                <Alert className="alert" severity="error" onClose={() => {
                    setShowNegativeAlert(false)
                }}>
                    <AlertTitle>Chyba.</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
            <div className="datatable">
                <div className="datatableTitle">
                    Seznam kategorií
                    <div className="links">
                        {multipleRowsSelected && (
                            <Button variant="outlined" className="linkDeleteMany"
                                    onClick={(event) => handleOpenModal("Opravdu chcete smazat označené kategorie?", null, true, event)}>
                                Smazat označené
                            </Button>
                        )}
                        <Link to="/admin/categories/new">
                            <Button
                                variant="outlined"
                                className="linkCreate"
                                component="label"
                            >
                                Vytvořit kategorii
                            </Button>
                        </Link>
                    </div>
                </div>
                <DataGrid
                    rows={data}
                    columns={userColumnCategories.concat(actionColumn)}
                    checkboxSelection
                    onRowSelectionModelChange={handleCheckboxSelection}
                    localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}},
                    }}
                    pageSizeOptions={[5, 10, 25]}
                />
                <DeleteModal
                    text={passedText}
                    handleOpen={(open) => setModalOpened(open)}
                    opened={modalOpened}
                    id={raceToDeleteId}
                    handleDelete={deleteMany ? handleDeleteMany : handleDelete}
                />
            </div>
        </>
    );
};

export default CategoryDatatableAdmin;
