import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import axios from "axios";
import './home.scss';
import MainSidebar from "../../components/sidebar/mainSidebar";
import NavBar from "../../components/navbar/navbar.jsx";
import Widget from "../../components/widget/Widget.jsx";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Featured from "../../components/featured/featured";
import toast, {Toaster} from 'react-hot-toast';

const Home = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [statistics, setStatistics] = useState({});

    const loadStatistics = async () => {
        let loadStatisticsResponse = await axios.get("/api/statistics/get");
        setStatistics(loadStatisticsResponse.data.statistics)
    }
    const verifyCookie = async () => {
        if (!cookies.token) {
            navigate("/login");
            return false;
        } else {
            const {data} = await axios.post("/api/", {}, {withCredentials: true}
            );
            const {status} = data;
            if (status) {
                toast.success('Přihlášen jako Administrátor')
                return true
            } else {
                removeCookie("token");
                navigate("/admin");
                return false
            }
        }
    };

    useEffect(() => {
        if (verifyCookie()) {
            loadStatistics()
        }
    }, [cookies, navigate, removeCookie]);

    return (
        <div className="home">
            <MainSidebar/>
            <div className="homeContainer">
                <NavBar/>
                <div className="widgets">
                    <Widget
                        type="ZÁVODY"
                        showAll="Zobrazit všechny závody"
                        amount={statistics?.races?.totalAmount || 0}
                        shodAdd={true} addLink="/admin/races/new"
                        showAllLink="/admin/races"
                        icon={<ShutterSpeedIcon className="icon"/>}
                    />
                    <Widget
                        type="KATEGORIE"
                        showAll="Zobrazit všechny kategorie"
                        amount={statistics?.categories?.totalAmount || 0}
                        shodAdd={true} addLink="/admin/categories/new"
                        showAllLink="/admin/categories"
                        icon={<GroupsIcon className="icon"/>}
                    />
                    <Widget
                        type="VÝSLEDKY"
                        showAll="Zobrazit všechny výsledky"
                        amount={statistics?.results?.totalAmount || 0}
                        shodAdd={false}
                        showAllLink="/admin/results"
                        icon={<AssignmentIcon className="icon"/>}
                    />
                    <Widget
                        type="ZÁVODNÍCI"
                        showAll="Zobrazit všechny závodníky"
                        amount={statistics?.racers?.totalAmount || 0}
                        shodAdd={false}
                        showAllLink="/admin/racers"
                        icon={<SportsMotorsportsIcon className="icon"/>}
                    />
                </div>
                <div className="charts">
                    <Featured
                        isRaceActive={statistics?.isRaceActive || false}
                        data={statistics?.activeRaceData || null}
                        percentage={statistics?.activeRacePercentage || 0}
                    />
                    <div style={{display: "flex", flex: 4}}></div>
                </div>
                <div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                </div>
            </div>
        </div>
    );
}
export default Home
