import "./update.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import {useNavigate, useParams} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Switch} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {translateGender} from "../../helper/gender-helper";
import OrgSidebar from "../../components/sidebar/orgSidebar";
import moment from "moment-timezone";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const UpdateRace = ({inputs}) => {
    let {id} = useParams();
    const navigate = useNavigate();
    const [showPositiveAlert, setShowPositiveAlert] = useState(false);
    const [showNegativeAlert, setShowNegativeAlert] = useState(false);
    const [errorMessageParams, setErrorMessageParams] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [categoryContextValues, setCategoryContextValues] = useState({});
    const [options, setOptions] = useState([])
    const [formData, setFormData] = useState({});
    const [updatedRaceName, setUpdatedRaceName] = useState("");
    const [customRowsCount, setCustomRowsCount] = useState(0);
    const [customRowsContextValues, setCustomRowsContextValues] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [rangeEnabled, setRangeEnabled] = useState(true);


    const adjustCategoryContextValues = (updatedNames) => {
        let updatedCategoryContextValues = categoryContextValues;
        Object.keys(categoryContextValues).forEach((key) => {
            if (!updatedNames.includes(key)) {
                delete updatedCategoryContextValues[key];
            }
        })
        setCategoryContextValues(updatedCategoryContextValues);
    }

    const handleCategoryContextValuesChange = (e, id) => {
        let [value, type] = id.split(";");
        setCategoryContextValues({
            ...categoryContextValues,
            [value]: {
                ...categoryContextValues[value],
                [type]: e.target.value
            }
        });
    }
    const handleRemoveCustomRow = (id) => {
        const updatedCustomRowValues = {...customRowsContextValues};

        delete updatedCustomRowValues[id];

        for (let key in updatedCustomRowValues) {
            const originalKey = parseInt(key);
            const adjustedKey = originalKey > id ? originalKey - 1 : originalKey;
            if (originalKey !== adjustedKey) {
                updatedCustomRowValues[adjustedKey] = {...updatedCustomRowValues[key]};
                delete updatedCustomRowValues[key];
            }
        }

        setCustomRowsContextValues(updatedCustomRowValues);
        setCustomRowsCount(customRowsCount - 1);
    };

    const handleChangeCustomRowValues = (e, id, type) => {
        if (!customRowsContextValues[id]) {
            let object = {
                type: "text",
                [type]: e.target.value,
            }
            let updatedValues = {
                ...customRowsContextValues,
                [id]: object
            }
            setCustomRowsContextValues(updatedValues);
        } else {
            let updatedValues = {
                ...customRowsContextValues,
                [id]: {
                    ...customRowsContextValues[id],
                    [type]: e.target.value,
                }
            }
            setCustomRowsContextValues(updatedValues);
        }
    }
    const handleAddCustomRow = () => {
        setCustomRowsCount(customRowsCount + 1);
    }
    const handleInputChange = (e, id) => {
        switch (id) {
            case "category": {
                const newChipName = e.target.value[categoryNames.length].name;
                const newChipId = e.target.value[categoryNames.length].id;

                const oldNames = [...categoryNames];
                const oldCategoryIds = formData[id] ? formData[id] : [];

                const isNameInArray = oldNames.includes(newChipName);

                let updatedNames;
                let updatedCategoryIds;

                if (isNameInArray) {
                    updatedNames = oldNames.filter((name) => name !== newChipName);
                    updatedCategoryIds = oldCategoryIds.filter((id) => id !== newChipId);
                } else {
                    updatedNames = [...oldNames, newChipName];
                    updatedCategoryIds = [...oldCategoryIds, newChipId];
                }

                setCategoryNames(updatedNames);
                setFormData({
                    ...formData,
                    [id]: updatedCategoryIds
                });
                adjustCategoryContextValues(updatedNames);
                break;
            }
            case "enableRange":
                setRangeEnabled(e.target.checked);
                setFormData({
                    ...formData,
                    [id]: e.target.checked
                });
                break;
            case "closed":
                setFormData({
                    ...formData,
                    [id]: e.target.checked
                });
                break;
            default:
                setFormData({
                    ...formData,
                    [id]: e.target.value
                });
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleCreate();
    };

    const assembleCustomRows = () => {
        let customRows = [];

        Object.values(customRowsContextValues).forEach((value) => {
            // Check if value.label is not undefined or null before accessing its properties
            if (value && value.label !== undefined && value.label !== null) {
                customRows.push({
                    name: value.label.toLowerCase().replace(/\s/g, ""),
                    label: value.label,
                    type: "text",
                    placeholder: value.placeholder || "" // Use an empty string if value.placeholder is undefined or null
                });
            }
        });

        return customRows;
    };

    const assembleCategoryContextList = () => {
        let categoryContextList = [];
        if (rangeEnabled === false) {
            categoryNames.forEach((name) => {
                let object = {
                    id: options.filter(option => option.name === name)[0].id
                }
                categoryContextList.push(object)
            })
        } else {
            categoryNames.forEach((name) => {
                let object = {
                    id: options.filter(option => option.name === name)[0].id,
                    startNumberFrom: categoryContextValues[name]?.from !== undefined &&
                    categoryContextValues[name]?.from !== null
                        ? parseInt(categoryContextValues[name].from)
                        : null,
                    startNumberTo: categoryContextValues[name]?.to !== undefined &&
                    categoryContextValues[name]?.to !== null
                        ? parseInt(categoryContextValues[name].to)
                        : null,
                }
                categoryContextList.push(object)
            })
        }
        return categoryContextList
    }

    const handleCreate = async () => {
        try {
            let raceUpdateData = {
                id: id,
                name: formData.name ?? null,
                registrationEnd: formData.registrationEnd ?? null,
                registrationStart: formData.registrationStart ?? null,
                start: formData.start ?? null,
                type: "default",
                closed: formData.closed ?? false,
                maxRacers: parseInt(formData.maxRacers) ?? null,
                created: new Date(),
                customRows: assembleCustomRows(),
                categoryContextList: assembleCategoryContextList(),
                rangeEnabled: rangeEnabled ?? null
            }
            let raceUpdateResponse = await axios.post("/api/race/update", raceUpdateData);
            setUpdatedRaceName(raceUpdateResponse.data.updatedRace.name);
            setShowPositiveAlert(true);
            setFormData({});
            setCategoryNames([])
            const response = await axios.post('/api/', {}, {withCredentials: true});
            const {type} = response.data;
            setTimeout(() => {
                setShowPositiveAlert(false);
                if (type === "ADMIN") {
                    navigate("/admin/races")
                } else {
                    navigate("/admin/organizator")
                }
            }, 2000);
        } catch (error) {
            setErrorMessageParams(Object.values(error.response.data.paramMap));
            setShowNegativeAlert(true);
        }
    };

    const verifyAdmin = async () => {
        const {data} = await axios.post("/api/", {}, {withCredentials: true});
        if (data.status && data.type === "ADMIN") {
            setIsAdmin(true);
        }
    };

    useEffect(() => {
        verifyAdmin()
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let raceListResponse = await axios.get("/api/race/get", {params: {id: id}});
            let categoryIdList = raceListResponse.data.race.categoryContextList.map(category => category.id);
            let allCategoriesResponse = await axios.post("/api/category/list", {});
            let filteredCategories = allCategoriesResponse.data.itemList.filter(category => categoryIdList.includes(category._id));
            console.log(raceListResponse);
            let defaultValues = {
                name: raceListResponse.data.race.name,
                registrationStart: moment(raceListResponse.data.race.registrationStart).format("YYYY-MM-DDTHH:mm"),
                registrationEnd: moment(raceListResponse.data.race.registrationEnd).format("YYYY-MM-DDTHH:mm"),
                start: moment(raceListResponse.data.race.start).format("YYYY-MM-DDTHH:mm"),
                maxRacers: raceListResponse.data.race.maxRacers,
                closed: raceListResponse.data.race.closed,
            };
            let options = allCategoriesResponse.data.itemList.map(category => {
                return {
                    name: category.name + " / " + translateGender(category.gender),
                    id: category._id
                }
            })
            let enabledRange = raceListResponse.data.race.categoryContextList.length > 0 &&
            raceListResponse.data.race.categoryContextList.every(item =>
              item.hasOwnProperty('startNumberFrom') && item.hasOwnProperty('startNumberTo')
            )
            console.log(enabledRange)
            setRangeEnabled(enabledRange);
            setOptions(options);
            setFormData(defaultValues);
            setCategoryNames(filteredCategories.map(category => category.name + " / " + translateGender(category.gender)));
            setCategoryContextValues(assembleCategoryContextDefaultContextValues(filteredCategories, raceListResponse.data.race.categoryContextList));
            setCustomRowsCount(raceListResponse.data.race.customRows.length);
            setCustomRowsContextValues(assembleCustomRowsContextDefaultObject(raceListResponse.data.race.customRows));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const assembleCategoryContextDefaultContextValues = (categoryList, categoryContextList) => {
        let object = {};
        categoryList.forEach((category, index) => {
            object[category.name + " / " + translateGender(category.gender)] = {
                from: categoryContextList[index]?.startNumberFrom,
                to: categoryContextList[index]?.startNumberTo
            }
        })
        return object
    }

    const assembleCustomRowsContextDefaultObject = (customRows) => {
        let object = {};
        Object.values(customRows).forEach((customRow, index) => {
            object[index] = {
                label: customRow.name,
                placeholder: customRow.placeholder
            }
        })

        return object;
    }

    return (
        <div className="update">
            {isAdmin ? <MainSidebar/> : <OrgSidebar/>}
            <div className="updateFormContainer" style={{paddingTop: "2vh"}}>
                <div className="wrapper">
                    <div className="header">
                        <h4>{"Upravit závod"}</h4>
                    </div>
                    <div className="dataSection" style={{paddingBottom: "20px"}}>
                        <div className="right">
                            <h2>Základní informace</h2>
                            <form onSubmit={handleSubmit}>
                                {inputs.map((input, index) => (
                                    <div className="formInput" key={index}>
                                        <label>{input.label}</label>
                                        <input
                                            value={formData[input.id] || ""}
                                            type={input.type}
                                            key={index}
                                            placeholder={input.placeholder}
                                            onChange={(e) => handleInputChange(e, input.id)}
                                        />
                                    </div>
                                ))}
                            </form>
                        </div>
                    </div>
                    <div className="dataSection">
                        <h2 style={{width: "100%"}}>Nastavení</h2>
                        <div className="checkboxes">
                            <label>{"Závod uzavřen:"}</label>
                            <Switch checked={formData.closed} color="warning"
                                    onChange={(e) => handleInputChange(e, "closed")}/>
                            <label>{"Povolit rozsahy pro kategorie:"}</label>
                            <Switch checked={rangeEnabled} color="warning"
                                    onChange={(e) => handleInputChange(e, "enableRange")}/>
                        </div>
                    </div>
                    <div className="dataSection">
                        <div className="right">
                            <h2>Vlastní pole</h2>
                            <form onSubmit={handleSubmit}>
                                {Array.from({length: customRowsCount}, (_, index) => (
                                    <>
                                        <div className="formInput" key={index + "label"}>
                                            <label>Název</label>
                                            <input
                                                value={customRowsContextValues[index]?.label || ""}
                                                type="text"
                                                placeholder={"např. Typ motorky"}
                                                onChange={(e) => handleChangeCustomRowValues(e, index, "label")}
                                            />
                                        </div>
                                        <div className="formInput" key={index + "placeholder"}>
                                            <label>Napověda</label>
                                            <input
                                                placeholder={"např. Kawasaki"}
                                                value={customRowsContextValues[index]?.placeholder || ""}
                                                type="text"
                                                onChange={(e) => handleChangeCustomRowValues(e, index, "placeholder")}
                                            />
                                        </div>
                                        <div className="formIcon" key={index + "close"}>
                                            <CloseIcon style={{color: "#FF6000"}}
                                                       onClick={() => handleRemoveCustomRow(index)}/>
                                        </div>
                                    </>
                                ))}
                            </form>
                            <AddIcon className="addIcon" onClick={handleAddCustomRow}/>
                        </div>
                    </div>
                    <div className="dataSection" style={{borderBottom: "none"}}>
                        <div className="right">
                            <h2>Kategorie</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="formCategoryInput">
                                    <label>Výběr kategorií</label>
                                    <FormControl style={{width: "100%"}}>
                                        <InputLabel id="demo-multiple-chip-label"></InputLabel>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={categoryNames}
                                            onChange={(e) => handleInputChange(e, "category")}
                                            input={<OutlinedInput id="select-multiple-chip"/>}
                                            sx={{
                                                backgroundColor: "#f5f5f5",
                                                border: "0.5px solid rgb(180, 178, 178)"
                                            }}
                                            renderValue={(selected) => (
                                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5, width: "100%"}}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value}/>
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {options.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                {
                                    rangeEnabled ? (
                                        <div style={{width: "100%"}}>
                                            {categoryNames.length > 0 && (
                                                categoryNames.map((category, idx) => (
                                                    <div key={idx} className="categoryRow">
                                                        <div className="labelRow">
                                                            <label>Rozsah startovacích čísel pro
                                                                kategorii {category}:</label>
                                                        </div>
                                                        <div className="inputRow">
                                                            <p>Od:</p>
                                                            <input
                                                                value={categoryContextValues[category]?.from || ""}
                                                                type="number"
                                                                onChange={(e) => handleCategoryContextValuesChange(e, `${category};from`)}
                                                            />
                                                            <p>Do:</p>
                                                            <input
                                                                value={categoryContextValues[category]?.to || ""}
                                                                type="number"
                                                                onChange={(e) => handleCategoryContextValuesChange(e, `${category};to`)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{width: "100%"}}></div>
                                    )
                                }
                                <Button type="submit">Upravit</Button>
                            </form>
                        </div>
                    </div>
                    {showPositiveAlert && (
                        <Alert style={{width: "100%"}} icon={<CheckIcon fontSize="inherit"/>} severity="success">
                            Závod {updatedRaceName} úspěšně upraven!
                        </Alert>
                    )}
                    {showNegativeAlert && (
                        <Alert style={{width: "100%"}} severity="error" onClose={() => {
                            setShowNegativeAlert(false)
                        }}>
                            <AlertTitle>Závod se nepodařilo Upravit.</AlertTitle>
                            {errorMessageParams.map((param, index) => (
                                <p style={{margin: 0, paddingTop: 2}} key={index}>{param}</p>
                            ))}
                        </Alert>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateRace;
