export const racerInputsAdmin = [
    {
        id: "name",
        label: "Jméno",
        type: "text",
        placeholder: "Jan",
    },
    {
        id: "surname",
        label: "Příjmení",
        type: "text",
        placeholder: "Novák",
    },
    {
        id: "nationality",
        label: "Národnost",
        type: "text",
        placeholder: "CZ",
    },
    {
        id: "email",
        label: "E-mail",
        type: "email",
        placeholder: "jan.noval@gmail.com",
    },
    {
        id: "phoneNumber",
        label: "Telefonní číslo",
        type: "tel",
        placeholder: "+xxx 111 222 333",
    },
    {
        id: "dateOfBirth",
        label: "Rok narození",
        type: "date",
        placeholder: "1990",
    },
    {
        id: "gender",
        label: "Pohlaví",
        type: "select",
        options: ["Muž", "Žena"],
    },
    {
        id: "paid",
        type: "checkbox",
        label: "Zaplaceno",
    }
];

export const racerInputsMain = [
    {
        id: "name",
        label: "NAME",
        type: "text",
        placeholder: "Jan",
    },
    {
        id: "surname",
        label: "SURNAME",
        type: "text",
        placeholder: "Novák",
    },
    {
        id: "nationality",
        label: "NATIONALITY",
        type: "text",
        placeholder: "CZ/SK/PL",
    },
    {
        id: "email",
        label: "EMAIL",
        type: "email",
        placeholder: "jan.novak@gmail.com",
    },
    {
        id: "phoneNumber",
        label: "PHONE_NUMBER",
        type: "tel",
        placeholder: "+xxx 111 222 333",
    },
    {
        id: "dateOfBirth",
        label: "DATE_OF_BIRTH",
        type: "date",
        placeholder: "1990",
    },
    {
        id: "gender",
        label: "GENDER",
        type: "select",
        options: ["Muž", "Žena"],
    }
];

export const raceInputsCreate = [
    {
        id: "name",
        label: "Název",
        type: "text",
        placeholder: "První závod",
    },
    {
        id: "start",
        label: "Začátek závodu",
        type: "datetime-local",
    },
    {
        id: "registrationStart",
        label: "Začátek registrace",
        type: "datetime-local",
    },
    {
        id: "registrationEnd",
        label: "Konec registrace",
        type: "datetime-local",
    },
    {
        id: "maxRacers",
        label: "Maximální počet závodníků",
        type: "number",
    },
    {
        id: "organizatorName",
        label: "Uživatelské jméno organizátora",
        type: "text"
    },
    {
        id: "organizatorPassword",
        label: "Heslo organizátora",
        type: "text"
    }
];

export const raceInputsUpdate = [
    {
        id: "name",
        label: "Název",
        type: "text",
        placeholder: "První závod",
    },
    {
        id: "start",
        label: "Začátek závodu",
        type: "datetime-local",
    },
    {
        id: "registrationStart",
        label: "Začátek registrace",
        type: "datetime-local",
    },
    {
        id: "registrationEnd",
        label: "Konec registrace",
        type: "datetime-local",
    },
    {
        id: "maxRacers",
        label: "Maximální počet závodníků",
        type: "number",
    }
];

export const categoryInputs = [
    {
        id: "name",
        label: "Název",
        type: "text",
        placeholder: "Junior",
    },
    {
        id: "gender",
        label: "Pohlaví",
        type: "select",
        options: ["Muž", "Žena", "Unisex"]
    },
    {
        id: "ageFrom",
        label: "Věk od",
        type: "number",
        placeholder: "1"
    },
    {
        id: "ageTo",
        label: "Věk do",
        type: "number",
        placeholder: "18"
    }
];
