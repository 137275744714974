import "./styles/datatable.scss";
import {DataGrid} from "@mui/x-data-grid";
import {userColumnResultsAdmin} from "../../data/datatable-source";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import DeleteModal from "../modal/deleteModal";
import {csCZ} from "@mui/x-data-grid/locales";

const ResultDatatableAdmin = () => {
    const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);
    const [checkBoxParams, setCheckBoxParams] = useState([]);
    const fileInputRef = useRef(null);
    const [data, setData] = useState([]);
    const [modalOpened, setModalOpened] = useState(false);
    const [passedText, setPassedText] = useState("");
    const [deleteMany, setDeleteMany] = useState(false);
    const [raceToDeleteId, setRaceToDeleteId] = useState("");

    const downloadPDF = async (filename) => {
        try {
            const response = await axios.get(`/api/files/${filename}`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleDelete = async (filename) => {
        try {
            await axios.post(`/api/delete/${filename}`);
            setData(data.filter((item) => item.name !== filename));
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }

    };

    const handleCheckboxSelection = (params) => {
        params.length > 0 ? setMultipleRowsSelected(true) : setMultipleRowsSelected(false);
        setCheckBoxParams(params);
    }

    const handleDeleteMany = async () => {
        for (let id of checkBoxParams) {
            await axios.post(`/api/delete/${id}`);
        }
        await fetchData();
    }

    const handleFileUpload = async () => {

        fileInputRef.current.click();

        await new Promise((resolve) => {
            fileInputRef.current.addEventListener('change', resolve, {once: true});
        });

        const files = fileInputRef.current.files;
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('pdf', files[i]);
        }

        try {
            await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            await fetchData();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const fetchData = async () => {
        try {
            let response = await axios.get('/api/files');
            let data = response.data.itemList.map((item) => {
                return {
                    id: item.filename, name: item.filename,
                };
            })
            setData(data);
        } catch (error) {
            console.error('Error fetching PDFs:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, []);

    const handleOpenModal = (text, id, many, event) => {
        event.stopPropagation(); // Stop event propagation
        setPassedText(text);
        setRaceToDeleteId(id);
        setModalOpened(true);
        setDeleteMany(many);
    }

    const actionColumn = [{
        field: "action",
        headerName: "Akce",
        width: 300,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            const handleDownloadClick = (event) => {
                event.stopPropagation(); // Stop propagation of the click event
                downloadPDF(params.row.name);
            };

            return (<div className="cellAction">
                <div
                    className="deleteButton"
                    onClick={(event) => handleOpenModal("Opravdu chcete smazat výsledek: " + params.row.name + " ?", params.row.id, false, event)}
                >
                    Smazat
                </div>
                <div
                    className="downloadButton"
                    onClick={handleDownloadClick} // Call the new handler
                >
                    Stáhnout
                </div>
                <Link to={`pdf/${params.row.name}`} style={{textDecoration: "none"}}>
                    <div
                        className="viewButton"
                    >
                        Zobrazit
                    </div>
                </Link>
            </div>);
        },
    },];
    return (<>
        <div className="datatable">
            <div className="datatableTitle">
                Seznam výsledků
                <div className="links">
                    {multipleRowsSelected && (
                        <Button variant="outlined" className="linkDeleteMany"
                                onClick={(event) => handleOpenModal("Opravdu chcete smazat označené výsledky?", null, true, event)}>
                            Smazat označené
                        </Button>)}
                    <Button
                        variant="outlined"
                        className="linkCreate"
                        component="label"
                        onClick={handleFileUpload}
                    >
                        Přidat výsledek
                        <input
                            type="file"
                            accept=".pdf"
                            multiple  // Allow multiple file selection
                            ref={fileInputRef}
                            hidden
                        />
                    </Button>
                </div>
            </div>
            <DataGrid
                rows={data}
                columns={userColumnResultsAdmin.concat(actionColumn)}
                checkboxSelection
                onRowSelectionModelChange={handleCheckboxSelection}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: {paginationModel: {pageSize: 10}},
                }}
                pageSizeOptions={[5, 10, 25]}
            />
            <DeleteModal
                text={passedText}
                handleOpen={(open) => setModalOpened(open)}
                opened={modalOpened}
                id={raceToDeleteId}
                handleDelete={deleteMany ? handleDeleteMany : handleDelete}
            />
        </div>
    </>);
};

export default ResultDatatableAdmin;
