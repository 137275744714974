import React from 'react';
import './list.scss';
import MainSidebar from "../../components/sidebar/mainSidebar";
import NavBar from "../../components/navbar/navbar";
import CategoryDatatableAdmin from "../../components/datatable/categoryDatatableAdmin";

const ListCategories = () => {
    return (
        <div className="list">
            <MainSidebar/>
            <div className="listContainer">
                <NavBar/>
                <CategoryDatatableAdmin/>
            </div>
        </div>
    );
}
export default ListCategories
