import "./registrationForm.scss";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Button, Grid} from "@mui/material";
import NumberGridModal from "../../../adminpanel/components/modal/numberGrid";
import {translateGender} from "../../../adminpanel/helper/gender-helper";
import Navbar from '../../components/navbar/navbar';
import {toLocaleLanguage} from "../../components/translator/translation-helper";
import LanguageContext from "../../components/translator/languageContext";
import {useNavigate} from "react-router-dom";


const RegistrationForm = ({inputs}) => {
    const navigate = useNavigate();
    const {currentLang} = useContext(LanguageContext);
    const raceId = localStorage.getItem('raceIdRegistration');
    const [formData, setFormData] = useState({paid: false});
    const [showPositiveAlert, setShowPositiveAlert] = useState(false);
    const [showNegativeAlert, setShowNegativeAlert] = useState(false);
    const [errorMessageParams, setErrorMessageParams] = useState([]);
    const [customRows, setCustomRows] = useState([]);
    const [customRowsContextValues, setCustomRowsContextValues] = useState({});
    const [modalOpened, setModalOpened] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [multipleCategories, setMultipleCategories] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(undefined);
    const [selectedCategoryName, setSelectedCategoryName] = useState(undefined);
    const [selectedStartNumber, setSelectedStartNumber] = useState(null);
    const [botDetected, setBotDetected] = useState(false);
    const [raceName, setRaceName] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleInputChange = async (e, id) => {
        switch (id) {
            case "paid":
                setFormData({
                    ...formData,
                    [id]: e.target.checked
                });
                break;
            case "gender": {
                setFormData({
                    ...formData,
                    [id]: e.target.value
                });
                const gender = e.target.value;
                if ((gender === "Muž" || gender === "Žena") && formData["dateOfBirth"] && formData["dateOfBirth"] !== null) {
                    await assignCategory(e.target.value, formData["dateOfBirth"]);
                } else {
                    setSelectedCategoryId(undefined)
                    setSelectedCategoryName(undefined)
                    setSelectedStartNumber(null)
                }
                break;
            }
            case "dateOfBirth": {
                setFormData({
                    ...formData,
                    [id]: e.target.value
                });
                const genderValues = ["Muž", "Žena"];
                if (genderValues.includes(formData["gender"]) && e.target.value) {
                    await assignCategory(formData["gender"], e.target.value);
                } else {
                    setSelectedCategoryId(undefined)
                    setSelectedCategoryName(undefined)
                    setSelectedStartNumber(null)
                }
                break;
            }
            default: {
                setFormData({
                    ...formData,
                    [id]: e.target.value
                });
                break;
            }
        }
    };

    const assignCategory = async (gender, dateOfBirth) => {
        let assignCategoryResponse = await axios.post("/api/race/assignCategoryToRacer", {
            raceId: raceId,
            gender: translateGender(gender),
            dateOfBirth: new Date(dateOfBirth)
        })
        if (assignCategoryResponse?.data.categoryList && assignCategoryResponse.data.categoryList.length > 0) {
            setCategoryList(assignCategoryResponse.data.categoryList)
            setSelectedStartNumber(null)
            setSelectedCategoryId(undefined)
            setMultipleCategories(true)

        } else if (assignCategoryResponse?.data.category) {
            setSelectedCategoryId(assignCategoryResponse.data.category._id)
            setSelectedCategoryName(assignCategoryResponse.data.category.name)
            setSelectedStartNumber(null)
            setCategoryList([])
            setMultipleCategories(false)
        } else {
            setSelectedCategoryName(assignCategoryResponse.data.message === "CANNOT_BE_CLASSIFIED" && "Nelze klasifikovat.");
            setSelectedCategoryId(undefined)
            setCategoryList([])
            setMultipleCategories(false)
            setSelectedStartNumber(null)
        }
    }

    const handleSubmit = async (e) => {
        if (botDetected === false) {
            setDisabled(true);
            e.preventDefault();
            await handleCreate();
        }
    };

    const assembleCustomRowsValues = () => {
        let customRowsValues = [];
        Object.entries(customRowsContextValues).forEach(([key, value]) => {
            customRowsValues.push({name: key, value: value});
        });
        return customRowsValues;
    };

    const handleCreate = async () => {
        try {
            let createRacerData = {
                name: formData.name,
                surname: formData.surname,
                gender: translateGender(formData.gender),
                dateOfBirth: formData.dateOfBirth,
                nationality: formData.nationality,
                categoryId: selectedCategoryId,
                startNumber: selectedStartNumber,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                raceId: raceId,
                customRows: assembleCustomRowsValues(),
                paid: formData.paid
            }
            await axios.post("/api/racer/create", createRacerData);
            setShowNegativeAlert(false);
            setShowPositiveAlert(true);
            setFormData({});
            setSelectedCategoryName(undefined)
            setSelectedStartNumber(null)
            setSelectedCategoryId(undefined)
            setCustomRowsContextValues({})
            await loadData();
            setTimeout(() => {
                setShowPositiveAlert(false);
                navigate("/registration")
            }, 3000);
        } catch (error) {
            setDisabled(false);
            console.error("Error fetching data:", error);
            console.error(error.response.data);
            setErrorMessageParams(Object.values(error.response.data.paramMap));
            setShowNegativeAlert(true);
        }
    };

    const handleChangeCustomRowValues = (e, id) => {
        setCustomRowsContextValues({
            ...customRowsContextValues,
            [id]: e.target.value
        });
    }

    const loadData = async () => {
        try {
            let raceGetResponse = await axios.get("/api/race/get", {
                params: {
                    id: raceId
                }
            })
            let customRowsSource = raceGetResponse.data.race.customRows.map((customRow) => {
                return {
                    id: customRow.name,
                    label: customRow.label,
                    type: customRow.text,
                    placeholder: customRow?.placeholder ?? ""
                }
            })
            setRaceName(raceGetResponse.data.race.name);
            setCustomRows(customRowsSource);
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    }

    useEffect(() => {
        if (raceId) {
            loadData()
        }else {
            navigate("/registration")
        }
    }, [raceId]);

    useEffect(() => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }, [showPositiveAlert, showNegativeAlert, errorMessageParams]);

    return (
        <div style={{background: "#303841", minHeight: "100vh"}}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                color="white"
                style={{paddingBottom: 20}}
            >
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={11} sx={{paddingTop: 5, textAlign: "center"}}>
                    <h2>{toLocaleLanguage("REGISTRATION_FOR_THE_RACE", currentLang) + ": " + raceName}</h2>
                </Grid>
                <Grid item xs={11} md={8.5} style={{paddingTop: 30, paddingBottom: 25, textAlign: "left", height: 55}}>
                    <h4>{toLocaleLanguage("BASIC_INFORMATION", currentLang)}</h4>
                </Grid>
                <form onSubmit={handleSubmit} style={{width: "100%"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        color="white"
                    >
                        {inputs.map((input) => (
                            <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}} key={input.id}>
                                <div className="formInput" key={input.id}>
                                    <br/>
                                    <label>{toLocaleLanguage(input.label, currentLang)}</label>
                                    <br/>
                                    {input.type === "select" ? (
                                        <FormControl variant="standard" style={{
                                            width: "100vw",
                                            maxWidth: "100%",
                                            border: "0.5px solid rgb(230, 227, 227)",
                                            textAlign: "left",
                                            borderRadius: "4px",
                                            backgroundColor: "#EEEEEE",
                                        }}>
                                            <Select
                                                disableUnderline={true}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                onChange={(e) => handleInputChange(e, input.id)}
                                                style={{textAlign: "left", paddingLeft: "5px"}}
                                                value={formData[input.id] || ""}
                                                key={input.id}
                                            >
                                                {input.options.map((option, index) => (
                                                    <MenuItem key={index}
                                                              value={option}>{toLocaleLanguage(option, currentLang)}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <input
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={(e) => handleInputChange(e, input.id)}
                                            style={{
                                                width: "100vw",
                                                height: 35,
                                                backgroundColor: "#eeeeee",
                                                maxWidth: "100%",
                                                padding: "5px",
                                                border: "0.5px solid rgb(230, 227, 227)",
                                                borderRadius: "4px"
                                            }}
                                            key={input.id}
                                            value={formData[input.id] || ""}
                                            {...(input.id === "dateOfBirth" && {
                                                min: "yyyy-mm-dd",
                                                max: "yyyy-mm-dd",
                                                step: "1",
                                            })}
                                        />
                                    )}
                                </div>
                            </Grid>
                        ))}
                        <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                            <div className="formInput" style={{display: "none"}}>
                                <br/>
                                <label>Zadej text</label>
                                <br/>
                                <input
                                    type="text"
                                    placeholder="Zadej text"
                                    onChange={() => setBotDetected(true)}
                                    key="fakeInput"
                                    style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "0.5px solid rgb(230, 227, 227)",
                                        borderRadius: "4px",
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={11} md={8.5}
                              style={{paddingTop: 50, paddingBottom: 25, textAlign: "left", height: 55}}>
                            <h4>{toLocaleLanguage("RACE_DATA", currentLang)}</h4>
                        </Grid>
                        {multipleCategories === false ? (
                            <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                                <div className="formText">
                                    <br/>
                                    <label>{toLocaleLanguage("CATEGORY", currentLang)}</label>
                                    <br/>
                                    <span style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        width: "100%",
                                        padding: "5px",
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "0.5px solid rgb(230, 227, 227)",
                                        borderRadius: "4px"
                                    }}>
                    {selectedCategoryName || toLocaleLanguage("UNASSIGNED", currentLang)}
                  </span>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                                <div className="formText">
                                    <br/>
                                    <label>Kategorie</label>
                                    <br/>
                                    <FormControl variant="standard" style={{
                                        width: '100%',
                                        border: "0.5px solid rgb(230, 227, 227)",
                                        textAlign: "left",
                                        borderRadius: "4px",
                                        backgroundColor: "#EEEEEE",
                                        padding: "1px",
                                    }}>
                                        <Select
                                            disableUnderline={true}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            style={{textAlign: "center"}}
                                            onChange={(e) => {
                                                setSelectedCategoryId(e.target.value);
                                                setSelectedStartNumber(null);
                                            }}
                                            value={selectedCategoryId || "Placeholder"}
                                            label="Age"
                                        >
                                            <MenuItem disabled value="Placeholder">
                                                {toLocaleLanguage("UNSELECTED", currentLang)}
                                            </MenuItem>
                                            {categoryList.map((category, index) => (
                                                <MenuItem key={index} value={category._id}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                            <div className="formText">
                                <br/>
                                <label>{toLocaleLanguage("START_NUMBER", currentLang)}</label>
                                <br/>
                                <span style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    width: "100%",
                                    padding: "5px",
                                    backgroundColor: "#EEEEEE",
                                    color: "black",
                                    border: "0.5px solid rgb(230, 227, 227)",
                                    borderRadius: "4px",
                                    cursor: "pointer"
                                }}
                                      onClick={() => setModalOpened(true)}>
                    {selectedStartNumber || toLocaleLanguage("UNSELECTED", currentLang)}
                  </span>
                            </div>
                        </Grid>
                        {customRows.length > 0 && (
                            <Grid item xs={11} md={8.5}
                                  style={{paddingTop: 50, paddingBottom: 25, textAlign: "left", height: 55}}>
                                <h4>{toLocaleLanguage("OTHER_INFORMATION", currentLang)}</h4>
                            </Grid>
                        )}
                        {customRows.length > 0 && customRows.map((customRow, index) => (
                            <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                                <div className="formInput" key={index + "label"} style={{width: "100%"}}>
                                    <br/>
                                    <label>{customRow.label}</label>
                                    <br/>
                                    <input
                                        value={customRowsContextValues[customRow.id] || ""}
                                        type="text"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#EEEEEE",
                                            width: "100%",
                                            padding: "5px",
                                            border: "0.5px solid rgb(230, 227, 227)",
                                            borderRadius: "4px"
                                        }}
                                        placeholder={customRow?.placeholder ?? ""}
                                        onChange={(e) => handleChangeCustomRowValues(e, customRow.id)}
                                    />
                                </div>
                            </Grid>
                        ))}
                        {customRows.length % 2 === 1 && (
                            <Grid item xs={11} md={4.01} style={{marginLeft: 20, marginRight: 20}}>
                            </Grid>
                        )}
                    </Grid>
                </form>
                <Grid item xs={11} md={11}
                      style={{alignItems: "center", textAlign: "center", paddingTop: 50, paddingBottom: 30}}>
                    <form onSubmit={handleSubmit}>
                        <Button type="submit" variant="contained" color="primary"
                                disabled={disabled}>{toLocaleLanguage("REGISTER", currentLang)}</Button>
                    </form>
                </Grid>
                <NumberGridModal
                    categoryId={selectedCategoryId}
                    raceId={raceId}
                    opened={modalOpened}
                    handleOpen={(open) => setModalOpened(open)}
                    selectedNumber={(number) => setSelectedStartNumber(number)}
                />
                {showPositiveAlert && (
                    <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
                        {toLocaleLanguage("REGISTRATION_SUCESS", currentLang)}
                    </Alert>
                )}
                {showNegativeAlert && (
                    <Alert severity="error" onClose={() => {
                        setShowNegativeAlert(false)
                    }}>
                        <AlertTitle>{toLocaleLanguage("REGISTRATION_FAILED", currentLang)}</AlertTitle>
                        {errorMessageParams.map((param, index) => (
                            <p style={{margin: 0, paddingTop: 2}} key={index}>{param}</p>
                        ))}
                    </Alert>
                )}
            </Grid>
        </div>
    );
}

export default RegistrationForm;

