import React from 'react';
import './list.scss';
import NavBar from "../../components/navbar/navbar";
import RacerDatatableOrganizator from "../../components/datatable/racerDatatableOrganizator";
import OrgSidebar from "../../components/sidebar/orgSidebar";

const ListRacersOrganizator = () => {
    return (
        <div className="list">
            <OrgSidebar/>
            <div className="listContainer">
                <NavBar/>
                <RacerDatatableOrganizator/>
            </div>
        </div>
    );
}
export default ListRacersOrganizator
