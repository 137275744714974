import "./styles/sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from 'react'
import {useCookies} from "react-cookie";
import axios from "axios";
import DeleteModal from "../modal/deleteModal";
import toast from 'react-hot-toast';


const MainSidebar = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [modalOpened, setModalOpened] = useState(false);
    const [passedText, setPassedText] = useState("");

    const logout = () => {
        removeCookie("token", {path: "/"});
        navigate("/admin");
    };

    const handleCleanLiveResults = async () => {
        await axios.get("/results/clear", {});
        toast.success("Výsledky online resetovány.")
    }

    const handleOpenModal = (text, event) => {
        event.stopPropagation();
        setPassedText(text);
        setModalOpened(true);
    }

    return (
        <div className={`sidebar`}>
            <div className="top">
                <Link to="/admin/home" style={{textDecoration: "none", paddingTop: "10px"}}>
                    <span className="logo">casomira</span>
                </Link>
            </div>
            <hr/>
            <div className="center">
                <ul>
                    <p className="title">HLAVNÍ</p>
                    <Link to="/admin/home" style={{textDecoration: "none"}}>
                        <li>
                            <DashboardIcon className="icon"/>
                            <span>Statistika</span>
                        </li>
                    </Link>
                    <p className="title">PŘEHLED</p>
                    <Link to="/admin/races" style={{textDecoration: "none"}}>
                        <li>
                            <ShutterSpeedIcon className="icon"/>
                            <span>Závody</span>
                        </li>
                    </Link>
                    <Link to="/admin/racers" style={{textDecoration: "none"}}>
                        <li>
                            <SportsMotorsportsIcon className="icon"/>
                            <span>Závodníci</span>
                        </li>
                    </Link>
                    <Link to="/admin/categories" style={{textDecoration: "none"}}>
                        <li>
                            <GroupsIcon className="icon"/>
                            <span>Kategorie</span>
                        </li>
                    </Link>
                    <Link to="/admin/results" style={{textDecoration: "none"}}>
                        <li>
                            <AssignmentIcon className="icon"/>
                            <span>Výsledky</span>
                        </li>
                    </Link>
                    <p className="title">NÁSTROJE</p>
                    <li onClick={(event) => handleOpenModal("Opravdu chcete resetovat výsledky online?", event)}>
                        <DeleteForeverIcon className="icon"/>
                        <span>Vyčistit VO</span>
                    </li>
                </ul>
            </div>
            <div className="bottom" onClick={logout}>
                <li>
                    <LogoutIcon className="icon"/>
                    <span>Odhlásit</span>
                </li>
            </div>
            <DeleteModal
                text={passedText}
                handleOpen={(open) => setModalOpened(open)}
                opened={modalOpened}
                id={null}
                handleDelete={handleCleanLiveResults}
            />
        </div>
    );
};

export default MainSidebar;
