import "./styles/navbar.scss";

const Navbar = () => {
    return (
        <div className="navbar">
            <div className="wrapper">
                <div className="items">
                </div>
            </div>
        </div>
    );
};

export default Navbar;
