import React, {useContext, useEffect, useState} from 'react';
import LanguageContext from "../translator/languageContext";
import {toLocaleLanguage} from "../translator/translation-helper";
import axios from "axios";
import {
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery
} from '@mui/material';
import image1 from '../imageGallery/images/casomira.gif';

const ResultsOnlineDatatable = ({type}) => {
    const {currentLang} = useContext(LanguageContext);
    const [selectedCategory, setSelectedCategory] = useState('displayAll');
    const [isRaceActive, setIsRaceActive] = useState(false);
    const [checkboxMarked, setCheckboxMarked] = useState(false);
    const isMobile = useMediaQuery('(max-width:960px)');
    const twoTables = useMediaQuery('(max-width:1211px)');
    const [responseData, setResponseData] = useState({
        header: {
            id: "",
            raceTime: "",
            state: "",
            bestLap: ""
        },
        categories: []
    });

    const fetchData = async () => {
        try {
            let getCurrentResultResponse = await axios.get("/results/getCurrent", {
                params: {
                    type: type
                }
            });
            const currentResultData = getCurrentResultResponse.data.currentResult;
            if (Object.keys(currentResultData).length !== 0) {
                setIsRaceActive(true);
                setResponseData({
                    header: {
                        id: currentResultData._id,
                        raceTime: currentResultData.raceTime,
                        state: currentResultData.state,
                        bestLap: currentResultData.bestLap === "" ? "---" : currentResultData.bestLap
                    },
                    categories: currentResultData.data
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data initially
        if (isRaceActive) {
            let fetchDataInterval = setInterval(fetchData, 5000); // Fetch data every 5 seconds
            return () => clearInterval(fetchDataInterval); // Cleanup interval on component unmount
        }
    }, [isRaceActive]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxMarked(event.target.checked);
    };

    return (
        isRaceActive ? (
            <div>
                <Grid container justifyContent="center" style={{paddingTop: "20px"}}>
                    <Grid item xs={11.5} md={6}>
                        <Grid container justifyContent="center">
                            <Grid item xs={11} md={4}>
                                <p>{toLocaleLanguage("RACE_STATUS", currentLang) + ' ' + (responseData.header.state === 'active' ? toLocaleLanguage("ACTIVE", currentLang) : toLocaleLanguage("ENDED", currentLang))}</p>
                            </Grid>
                            <Grid item xs={11} md={4} sx={{textAlign: {xs: "left", md: "center"}}}>
                                <p>{toLocaleLanguage("RACE_TIME", currentLang) + ' ' + responseData.header.raceTime}</p>
                            </Grid>
                            <Grid item xs={11} md={4} sx={{textAlign: {xs: "left", md: "right"}}}>
                                <p>{toLocaleLanguage("BEST_LAP", currentLang) + ' ' + responseData.header.bestLap}</p>
                            </Grid>
                            <Grid item xs={11} md={selectedCategory === 'displayAll' ? 6 : 12}>
                                <label>{toLocaleLanguage("DISPLAY_CATEGORY", currentLang)}</label>
                                <br/>
                                <FormControl variant="standard" style={{
                                    width: '250px',
                                    border: "0.5px solid rgb(230, 227, 227)",
                                    textAlign: "left",
                                    borderRadius: "4px",
                                    backgroundColor: "white",
                                }}>
                                    <Select
                                        disableUnderline={true}
                                        labelId="demo-simple-select-standard-label"
                                        id="category-select"
                                        onChange={handleCategoryChange}
                                        style={{textAlign: "left", paddingLeft: "5px"}}
                                        value={selectedCategory}
                                    >
                                        <MenuItem
                                            value="displayAll">{toLocaleLanguage("SHOW_ALL_CATEGORIES", currentLang)}</MenuItem>
                                        {responseData.categories.map(category => (
                                            <MenuItem key={category._id}
                                                      value={category.categoryName}>{category.categoryName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {selectedCategory === 'displayAll' && !isMobile && (
                                <Grid item xs={11} md={6} sx={{textAlign: {xs: "left", md: "right"}}}>
                                    <br/>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checkboxMarked}
                                            onChange={handleCheckboxChange}
                                            style={{marginRight: "10px"}}
                                        />
                                        {toLocaleLanguage("VIEW_SIDE_BY_SIDE", currentLang)}
                                    </label>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {selectedCategory === 'displayAll' ? (
                    <Grid container spacing={3} justifyContent="center" style={{marginBottom: "50px"}}>
                        {responseData.categories.map(category => (
                            <Grid item xs={11} md={
                                checkboxMarked && twoTables ? 5.8 :
                                    checkboxMarked && !twoTables ? 3.8 :
                                        !checkboxMarked ? 6.2 : 6.1
                            } style={{marginTop: "20px", marginBottom: "50px",}}>
                                <h2>{toLocaleLanguage("CATEGORY", currentLang) + ": " + category.categoryName}</h2>
                                <TableContainer>
                                    <Table aria-label="simple table" style={{width: "98%", marginLeft: "6px"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{
                                                    fontSize: {xs: '12px', md: '14px'},
                                                    color: 'white'
                                                }}>{toLocaleLanguage("POSITION", currentLang)}</TableCell>
                                                <TableCell align="center" sx={{
                                                    fontSize: {xs: '12px', md: '14px'},
                                                    color: 'white'
                                                }}>{toLocaleLanguage("START_NUMBER", currentLang)}</TableCell>
                                                <TableCell align="center" sx={{
                                                    fontSize: {xs: '12px', md: '14px'},
                                                    color: 'white'
                                                }}>{toLocaleLanguage("NAME_AND_SURNAME", currentLang)}</TableCell>
                                                <TableCell align="center" sx={{
                                                    fontSize: {xs: '12px', md: '14px'},
                                                    color: 'white'
                                                }}>{toLocaleLanguage("DIFFERENCE", currentLang)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {category.results.map(result => (
                                                <TableRow key={result._id}
                                                          sx={{color: 'white', fontSize: '9px', width: '10px'}}>
                                                    <TableCell align="center" component="th" scope="row" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white',
                                                        padding: "4px",
                                                        borderLeft: "1px solid white"
                                                    }}>
                                                        {result.position}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white',
                                                        padding: "4px",
                                                        borderLeft: "1px solid white"
                                                    }}>
                                                        {result.startNumber}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white',
                                                        padding: "4px",
                                                        borderLeft: "1px solid white"
                                                    }}>
                                                        {result.name}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row"
                                                               sx={{
                                                                   fontSize: {xs: '12px', md: '14px'},
                                                                   color: 'white',
                                                                   padding: "4px",
                                                                   borderLeft: "1px solid white",
                                                                   borderRight: "1px solid white"
                                                               }}>
                                                        {result.difference}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container spacing={0} justifyContent="center"
                          style={{marginTop: "20px", marginBottom: "50px"}}>
                        {responseData.categories.map(category => (
                            category.categoryName === selectedCategory && (
                                <Grid item xs={11} md={6} style={{
                                    marginTop: "20px",
                                    marginBottom: "50px",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                }}>
                                    <h2>Kategorie: {category.categoryName}</h2>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white'
                                                    }}>{toLocaleLanguage("POSITION", currentLang)}</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white'
                                                    }}>{toLocaleLanguage("START_NUMBER", currentLang)}</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white'
                                                    }}>{toLocaleLanguage("NAME_AND_SURNAME", currentLang)}</TableCell>
                                                    <TableCell align="center" sx={{
                                                        fontSize: {xs: '12px', md: '14px'},
                                                        color: 'white'
                                                    }}>{toLocaleLanguage("DIFFERENCE", currentLang)}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {category.results.map(result => (
                                                    <TableRow key={result._id}
                                                              sx={{color: 'white', fontSize: '9px', width: '10px'}}>
                                                        <TableCell align="center" component="th" scope="row" sx={{
                                                            fontSize: {xs: '12px', md: '14px'},
                                                            color: 'white',
                                                            padding: "4px",
                                                            borderLeft: "1px solid white"
                                                        }}>
                                                            {result.position}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" sx={{
                                                            fontSize: {xs: '12px', md: '14px'},
                                                            color: 'white',
                                                            padding: "4px",
                                                            borderLeft: "1px solid white"
                                                        }}>
                                                            {result.startNumber}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" sx={{
                                                            fontSize: {xs: '12px', md: '14px'},
                                                            color: 'white',
                                                            padding: "4px",
                                                            borderLeft: "1px solid white"
                                                        }}>
                                                            {result.name}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row"
                                                                   sx={{
                                                                       fontSize: {xs: '12px', md: '14px'},
                                                                       color: 'white',
                                                                       padding: "4px",
                                                                       borderLeft: "1px solid white",
                                                                       borderRight: "1px solid white"
                                                                   }}>
                                                            {result.difference}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        ))}
                    </Grid>
                )}
            </div>
        ) : (
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                textAlign="center"
                spacing={5}
                color="white"
                sx={{
                    paddingTop: 10
                }}
            >
                <Grid item xs={11} md={4.5}>
                    <img src={image1} alt="image1" style={{width: "100%"}}/>
                </Grid>
                <Grid item xs={11} md={11}>
                    <h3>{toLocaleLanguage("NO_RESULTS", currentLang)}</h3>
                </Grid>
            </Grid>
        )
    );
};

export default ResultsOnlineDatatable;
