import "./view.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {translateGender} from "../../helper/gender-helper";

const ViewRace = () => {
    let {id} = useParams();
    const [values, setValues] = useState({});

    function formatCustomDate(dateString) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        const hour = dateString.substring(11, 13);
        const minute = dateString.substring(14, 16);

        return `${day}.${month}.${year} ${hour}:${minute}`;
    }

    const fetchData = async () => {
        try {
            let raceResponse = await axios.get("/api/race/get", {
                params: {
                    id: id
                }
            });
            let categoryListResponse = await axios.post("/api/category/list", {
                filterList: [{
                    key: "id",
                    valueList: raceResponse.data.race.categoryContextList.map((categoryContext) => categoryContext.id)
                }]
            })
            let defaultValues = {
                "ID Závodu": id,
                "Název": raceResponse.data.race.name,
                "Začátek registrace": formatCustomDate(raceResponse.data.race.registrationStart),
                "Konec registrace": formatCustomDate(raceResponse.data.race.registrationEnd),
                "Začátek závodu": formatCustomDate(raceResponse.data.race.start),
                "Uzavřen": raceResponse.data.race.closed === true ? "Ano" : "Ne",
                "Maximální počet závodníků": raceResponse.data.race.maxRacers,
                "Vytvořeno": formatCustomDate(raceResponse.data.race.created),
                "Uživatelské jméno organizátora": raceResponse.data.organizator.username
            };

            categoryListResponse.data.itemList.forEach((category) => {
                let categoryContext = raceResponse.data.race.categoryContextList.find((context) => context.id === category._id)
                if (categoryContext.startNumberFrom === undefined && categoryContext.startNumberTo === undefined) {
                    defaultValues["Kategorie: " + category.name + " - " + translateGender(category.gender)] = "Rozsah není určen.";
                } else {
                    defaultValues["Kategorie: " + category.name + " - " + translateGender(category.gender)] = "Rozsah startovacích čísel: < " + categoryContext.startNumberFrom + " ; " + categoryContext.startNumberTo + " >";
                }
            })

            raceResponse.data.race.customRows.forEach((row, index) => {
                defaultValues["Vlastní pole " + ++index] = row.name
            })
            setValues(defaultValues);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="view">
            <MainSidebar/>
            <div className="viewContainer" style={{paddingTop: "5vh"}}>
                <div className="wrapper">
                    <div className="header">
                        <h4>{"Data závodu"}</h4>
                    </div>
                    <div className="dataSection">
                        {Object.entries(values).map(([key, value]) => (
                            <div className="dataItem" key={key}>
                                <label>{key}</label>
                                <span>{value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewRace;
