import {Avatar, Button, Grid, Paper, TextField} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import toast, {Toaster} from 'react-hot-toast';
import {useCookies} from "react-cookie";

const Login = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const handleError = (err) => toast.error(err)

    const handleInputChange = (id, e) => {
        setValues({
            ...values,
            [id]: e.target.value
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const {data} = await axios.post("/api/login", {
                    username: values.username, password: values.password
                },
                {withCredentials: true}
            );
            const {success, message, type, raceId} = data;
            if (success) {
                if (type === "ADMIN") {
                    setTimeout(() => {
                        navigate("/admin/home");
                    }, 1000);
                } else {
                    sessionStorage.setItem('raceId', raceId)
                    setTimeout(() => {
                        navigate("/admin/organizator");
                    }, 1000);
                }
            } else {
                handleError(message);
            }
        } catch (error) {
            console.error(error);
        }
        setValues({});
    };

    const verifyCookie = async () => {
        if (cookies.token) {
            const {data} = await axios.post("/api/", {}, {withCredentials: true}
            );
            const {type} = data;
            if (type === "ADMIN") {
                navigate("/admin/home");
            } else {
                navigate("/admin/organizator");
            }
        }
    };

    useEffect(() => {
        verifyCookie();
    }, [cookies, navigate, removeCookie]);

    return (
        <form style={{paddingTop: "50px"}} onSubmit={(e) => handleSubmit(e)}>
            <Paper elevation={10}
                   style={{paddingTop: 40, padding: 20, height: "320px", width: 280, margin: "20px auto"}}>
                <Grid align='center'>
                    <Avatar style={{backgroundColor: '#1bbd7e'}}><LockOutlinedIcon/></Avatar>
                    <h2 style={{padding: "5px"}}>Přihlášení</h2>
                </Grid>
                <TextField style={{paddingBottom: "10px"}} value={values["username"] || ""} label='Přihlašovací jméno'
                           type='text' fullWidth required onChange={(e) => handleInputChange("username", e)}/>
                <TextField value={values["password"] || ""} label='Heslo' placeholder='Zadejte heslo' type='password'
                           fullWidth required onChange={(e) => handleInputChange("password", e)}/>
                <Button type='submit' color='primary' variant="contained" style={{margin: '20px 0'}} fullWidth
                        onClick={(e) => handleSubmit(e)}>Přihlásit se</Button>
            </Paper>
            <div>
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
            </div>
        </form>
    )
}

export default Login
