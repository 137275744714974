import React, {useContext, useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import Navbar from '../../components/navbar/navbar';
import RacerDatatableMain from "../../components/datatable/racerDatatableMain";
import axios from "axios";
import {toLocaleLanguage} from "../../components/translator/translation-helper";
import LanguageContext from "../../components/translator/languageContext";

function OverviewForm() {
    const {currentLang} = useContext(LanguageContext);
    const raceId = sessionStorage.getItem('raceIdOverview');
    let [raceName, setRaceName] = useState("");

    const loadData = async () => {
        try {
            let race = (await axios.get("/api/race/get", {
                params: {
                    id: raceId
                }
            }));
            setRaceName(race.data.race.name);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        loadData()
    }, [raceId]);

    return (
        <div style={{background: "#303841", minHeight: "100vh"}}>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
                color="white"
            >
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                {raceName &&
                    <Grid item xs={11} md={8} sx={{
                        paddingTop: 3,
                        paddingBottom: {xs: 1, md: 2},
                        paddingLeft: 1,
                        textAlign: "start",
                        fontSize: {xs: "23px", md: "35px"}
                    }}>
                        {toLocaleLanguage("RACER_OVERVIEW", currentLang) + ": " + raceName}
                    </Grid>
                }
                <Grid item xs={11} md={8} sx={{paddingLeft: 1}}>
                    <RacerDatatableMain/>
                </Grid>
            </Grid>
        </div>
    );

}

export default OverviewForm;
