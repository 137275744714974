import "./view.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";

const ViewCategoryLinkedRaces = () => {
    let {id} = useParams();
    const [values, setValues] = useState({});
    const [categoryName, setCategoryName] = useState("");

    const fetchData = async () => {
        try {
            let categoryResponse = await axios.get("/api/category/get", {
                params: {
                    id: id
                }
            })
            let raceListResponse = await axios.post("/api/race/list", {
                filterList: [{
                    key: "categoryContextList.id",
                    value: id
                }]
            });
            let defaultValues = {}
            if (raceListResponse.data.itemList.length === 0) {
                defaultValues = {
                    "Upozornění!": "Kategorie není navázána na žádný závod.",
                }
            } else {
                raceListResponse.data.itemList.forEach((race) => {
                    let categoryContext = race.categoryContextList.filter((categoryContext) => categoryContext.id === id)
                    defaultValues["Závod: " + race.name] = "Rozsah startovacích čísel: " + categoryContext[0].startNumberFrom + " - " + categoryContext[0].startNumberTo
                })
            }

            setCategoryName(categoryResponse.data.name)
            setValues(defaultValues);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <div className="view">
        <MainSidebar/>
        <div className="viewContainer" style={{paddingTop: "5vh"}}>
            <div className="wrapper">
                <div className="header">
                    <h4>{"Navázané závody pro kategorii: " + categoryName}</h4>
                </div>
                <div className="dataSection">
                    {Object.entries(values).map(([key, value]) => (<div className="dataItem" key={key}>
                        <label>{key}</label>
                        <span>{value}</span>
                    </div>))}
                </div>
            </div>
        </div>
    </div>
}

export default ViewCategoryLinkedRaces
