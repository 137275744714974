import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import axios from 'axios';

const RequireOrganizatorAuth = ({children}) => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate('/admin');
            } else {
                try {
                    const response = await axios.post('/api/', {}, {withCredentials: true});
                    const {status, user, type} = response.data;

                    if (status) {
                        // User is authenticated, you can perform additional actions if needed
                    } else {
                        // Invalid token or authentication failed
                        removeCookie("token", {path: "/"});
                        navigate("/admin");
                    }
                } catch (error) {
                    // Handle error, e.g., network issue or server error
                    console.error('Authentication error:', error);
                    removeCookie("token", {path: "/"});
                    navigate("/admin");
                }
            }
        };

        verifyCookie();
    }, [cookies, navigate, removeCookie]);

    return <>{children}</>;
};

export default RequireOrganizatorAuth;
