import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
export const userColumnsRacers = [
    {
        field: "startNumber",
        headerName: "Starovací číslo",
        width: 120,
    },
    {
        field: "fullName",
        headerName: "Jméno a přijmení",
        width: 200,
    },
    {
        field: "category",
        headerName: "Kategorie",
        width: 200,
    },
    {
        field: "raceName",
        headerName: "Název závodu",
        width: 250,
    },
    {
        field: "paid",
        headerName: "Zaplaceno",
        width: 150,
        renderCell: (params) => {
          return params.value ? <DoneIcon/> : <CloseIcon/>
        }
    },
];

export const userColumnRaces = [
    {
        field: "name",
        headerName: "Název",
        width: 220,
    },
    {
        field: "date",
        headerName: "Start závodu",
        width: 160,
    },
    {
        field: "maxRacers",
        headerName: "Max závodníků",
        width: 140,
    },
    {
        field: "registeredRacers",
        headerName: "Registrovaných",
        width: 140,
    }
];
export const userColumnCategories = [
    {
        field: "name",
        headerName: "Název",
        width: 240,
    },
    {
        field: "gender",
        headerName: "Pohlaví",
        width: 240,
    },
    {
        field: "age",
        headerName: "Věkové omezení",
        width: 240,
    },
]
export const userColumnResultsAdmin = [
    {
        field: "name",
        headerName: "Výsledky",
        width: 500,
    },
];
